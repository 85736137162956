import React from "react";

import SEO from "../components/seo";

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div className="prose dark:prose-invert prose-lg md:py-12">
      <h1>Not found</h1>
      <p>This page does not exist.</p>
    </div>
  </>
);

export default NotFoundPage;
